<template>
  <b-sidebar
    id="edit-user-sidebar"
    :visible="isEditUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-edit-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          编辑联系人
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="editContact()"
          @reset.prevent="clearForm"
        >
          <validation-provider
            #default="validationContext"
            name="姓"
            rules="required"
          >
            <b-form-group
              label="姓"
              label-for="last-name"
            >
              <b-form-input
                id="last-name"
                v-model="newContactData.last_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="名"
            rules=""
          >
            <b-form-group
              label="名"
              label-for="first-name"
            >
              <b-form-input
                id="first-name"
                v-model="newContactData.first_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="性别"
            rules=""
          >
          
            <b-form-group
              label="性别"
              label-for="gender"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newContactData.gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="genderOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="称谓"
            rules=""
          >
            <b-form-group
              label="称谓"
              label-for="appellation"
            >
              <b-form-input
                id="appellation"
                v-model="newContactData.appellation"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="职位"
            rules=""
          >
            <b-form-group
              label="职位"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="newContactData.title"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="公司"
            rules=""
          >
            <b-form-group
              label="公司"
              label-for="company"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newContactData.company"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companyOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="newContactData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="电话"
            rules="integer"
          >
            <b-form-group
              label="电话"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="newContactData.phone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
             修改

            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isEditUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    contactData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    companyOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      genderOptions:[
        {label:'男',value:'male'},
        {label:'女',value:"female"},
      ],
      newContactData:{}
    }
  },
  methods:{
    editContact: function(){
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
            if(this.newContactData.gender == "") this.newContactData.gender = null
            this.newContactData.firstName = this.newContactData.first_name
            this.newContactData.lastName = this.newContactData.last_name
            this.newContactData.contactId = this.newContactData.contact_id
            this.$axios.post("vendors/contacts/edit_contact",this.newContactData).then((resp)=>{
              if(resp.data.status == "ok"){
                this.clearForm()
                this.$emit("get-contacts")
                this.$emit("get-contact")
                this.$emit('update:is-edit-user-sidebar-active', false)
              }
          })
          
        }
      })
    },
    clearForm(){
      this.$refs.refFormObserver.reset()
      this.newContactData = {}
    },
  },
  watch:{
    "isEditUserSidebarActive": function(val){
      if (val){
        this.newContactData = JSON.parse(JSON.stringify(this.contactData))
      }
    }
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
