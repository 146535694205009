<template>
  <div>
    <contact-edit
      :is-edit-user-sidebar-active.sync="isEditUserSidebarActive"
      :contact-data="contactSysData"
      :company-options="companyOptions"
      @get-contact="getContact"
    />

    <template v-if="contactSysData">
      <b-row class="match-height">
        <b-col
          cols="12"
          lg="6"
        >
          <contact-view-contact-info-card :contact-data="contactSysData" @edit-contact="editContact"/>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
        <contact-view-contact-wechat-card 
          :wechat-id="contactSysData.wechat_id"
          :wx-loaded="wxLoaded"
          :wx-contacts="wxContacts"
          :wx-contact-info="wxContactInfo"
          @get-contact="getContact"/>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <contact-view-contact-timeline-card 
            :timeline-data="timelineData"
            @get-timeline="getTimeline"
            />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import ContactViewContactInfoCard from './ContactViewContactInfoCard.vue'
import ContactViewContactPlanCard from './ContactViewContactPlanCard.vue'
import ContactViewContactTimelineCard from './ContactViewContactTimelineCard.vue'
import ContactViewContactWechatCard from './ContactViewContactWechatCard.vue'
import ContactEdit from '../system/ContactEdit.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    ContactViewContactInfoCard,
    ContactViewContactPlanCard,
    ContactViewContactTimelineCard,
    ContactViewContactWechatCard,
    InvoiceList,
    ContactEdit,
  },
  data(){
    return{
      companyOptions: [],
      systemEditSidebar: false,
      wxContacts: [],
      wxLoaded: false,
      contactSysData: {},
      wxContactInfo: {},
      isEditUserSidebarActive:false,
      timelineData: []
    }
  },
  methods:{
    editContact(){
      this.isEditUserSidebarActive = true;
    },
    getWxContacts(){
      if(!this.contactSysData.wechat_id){
        this.$axios.get("/third_party/wechat/get_contacts").then((response) => {
        this.wxContacts = response.data.data.contacts
        this.wxLoaded = true;
      })
    }else{
      this.getWXContactInfo();
    }
  },
    getWXContactInfo(){
      this.$axios.get("/third_party/wechat/get_contact_info/" + this.contactSysData.wechat_id).then((response) => {
      this.wxContactInfo = response.data.data.contact
    })
    },
    getContact(){
      this.$axios.get("vendors/contacts/get_contact/" + this.$route.params.id).then((res)=>{
      this.contactSysData = res.data.data.contact;
      this.getWxContacts();
      this.getTimeline()
    })
    },
    getTimeline(){
      this.$axios.get("vendors/contacts/get_timeline/" + this.$route.params.id).then((res)=>{
      this.timelineData = res.data.data.timeline;
    })
    },
    getVendorCompanies: function(){
      this.$axios.get("vendors/companies/get_companies").then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.companies.forEach((company)=>{
            this.companyOptions.push({label:company.name,value:company.company_id})
          })
        }
      })
    },
  },
  created(){
    this.getContact();
    this.getTimeline();
    this.getVendorCompanies()
  },
}
</script>

<style>

</style>
